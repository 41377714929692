(function($) {
  
    // Gravity Form
    if ( $( ".home .gform_validation_error" ).length ) {
      $('html, body').animate({
          scrollTop: $('.home .gform_validation_error').offset().top - 150
      }, 500);
    }

    if ( $( ".modal .gform_validation_error" ).length ) {
      $('#contact').modal('show');
    }

})(jQuery); 