(function($) {
  if ($(".js-slider-for").length) {
    $('.js-slider-for').slick({
    	dots: true,
    	arrows: false,
    	fade: true,
  		asNavFor: '.js-slider-nav'
    });
  } 

  if ($(".js-slider-nav").length) {
    $('.js-slider-nav').slick({
    	dots: false,
    	arrows: false,
  		asNavFor: '.js-slider-for'
    });
  } 

  if ($(".js-slider-gallery").length) {
    $('.js-slider-gallery').slick({
      infinite: false,
      dots: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 2,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
    ]
    });
  } 
})(jQuery);
 