(function($) {
  function Filter(element) {
    this.$el = $(element);
    this.$body = $('html, body');
  }

  Filter.prototype = {
    init: function() {
      var t = this;
      var ACTIVE = 'active';
      var INVISIBLE = 'd-none';
      var VISIBLE = 'visible';

      // Detect click event on button
      this.$el.each(function() {
        $(this).on('click', function(e) {
          e.preventDefault();
          var get_id = $(this).attr('id');
          var get_current = $('.filter .' + get_id);
          var target = $('.filter').offset().top - 250;
          $('.js-filter').removeClass(ACTIVE);
          $(this).addClass(ACTIVE);

          $('.filter-item').not( get_current ).removeClass(VISIBLE).addClass(INVISIBLE);
          get_current.removeClass(INVISIBLE).addClass(VISIBLE);
          t.$body.animate({ scrollTop: target }, 500);
        });
      });
    }
  };

  if ($(".js-filter").length) {
    var filter = new Filter('.js-filter');
    filter.init();
  }
})(jQuery);
