(function($) {

  function Parallax(element) {
    this.$el = $(element);
    this.$window = $(window);
  }

  Parallax.prototype = {
    init: function() {
      var t = this;
      var offset = this.$el.offset().top;
      this.$window.on('scroll', function() { 
        var x = $(this).scrollTop(); 
        t.$el.css('background-position', '0%' + parseInt(x / 10) + 'px');
      });
    } 
  }; 

  if ($('.js-parallax').length) {
    var parallax = new Parallax('.js-parallax');
    parallax.init();
  }
})(jQuery);
