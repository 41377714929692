(function($) {
  function Search(element) {
    this.$el = $(element);
    this.target = this.$el.data("target");
    this.$body = $('body');
    this.SEARCH_ACTIVE = 'is-active';
    this.SEARCH_OPEN = 'search-open';
  }

  Search.prototype = {
    init: function() {
      var t = this;
      this.$el.on('click', function(event) {
        t.$body.toggleClass(t.SEARCH_OPEN);
        $(t.target).toggleClass(t.SEARCH_ACTIVE).find('input').focus();

        // Close Navbar Collapse on mobile
        $('#navbar').collapse('hide');
        return false;
      });

      // Detect outside the container was clicked
      this.$body.on('click', function(event) {
        if ($(this).hasClass(t.SEARCH_OPEN)) {
          if ($(event.target).is(t.target) || $(event.target).is('.search-field')) {
            return false;
          }
         t.hidden();
        }
      });
      // Dismiss Search
     this.dismiss();
    },
    dismiss: function() {
      var t = this;
      $('[data-dismiss="search"]').on('click', function() {
        t.hidden(t.target);
      });
    },
    hidden: function() {
      $(this.target).removeClass(this.SEARCH_ACTIVE).find('input[type="search"]').val('');
      this.$body.removeClass(this.SEARCH_OPEN);
    },
  };

  if ($('[data-toggle="search"]').length) {
    var search = new Search('[data-toggle="search"]');
    search.init();
  }

})(jQuery);
